export const ROUTES = {
	LOGIN: "/login",
	HOME: "/home",
	DATA_MANAGEMENT: "/data",
	MODEL_MANAGEMENT: "/model",
	TEAM: "/teams",
	OTHER_USER: "/team/:teamId/user/:userId",
	DETAIL_TEAM: "/teams/:id",
	USER_SETTING: "/user-setting",
	FORGOT_PASSWORD: "/forgot-password",
	RESET_PASSWORD: "/reset-password",
	REGISTER: "/register",
	MASTER: "/master-setting",
	INFORMATION: "/information",
	DETAIL_DATA: "/data/:id",
	PROJECT: "/project",
}

export const ROLES = {
	ADMIN: "ADMIN",
	USER: "USER"
}

export const NUMBERCONSTANT = {
	FREEMINUTES: 5000
}

export const MENU = [
	{
		title: "Home",
		key: ROUTES.HOME,
		path: ROUTES.HOME,
		breadcrumb: ["Home"],
		protect: {
			must: ROLES.USER
		}
	},
	{
		title: "Project",
		key: ROUTES.PROJECT,
		path: ROUTES.PROJECT,
		breadcrumb: ["PROJECT"],
		protect: {
			must: ROLES.USER
		}
	},
	{
		title: "Data Management",
		key: ROUTES.DATA_MANAGEMENT,
		path: ROUTES.DATA_MANAGEMENT,
		breadcrumb: ["Data Management"],
		protect: {
			must: ROLES.USER
		}
	},
	{
		title: "Model Management",
		key: ROUTES.MODEL_MANAGEMENT,
		path: ROUTES.MODEL_MANAGEMENT,
		breadcrumb: ["Model Management"],
		protect: {
			must: ROLES.USER
		}
	},
	{
		title: "Team",
		key: ROUTES.DETAIL_TEAM,
		path: ROUTES.DETAIL_TEAM,
		breadcrumb: ["Team"],
		protect: {
			must: ROLES.USER
		}
	},
	{
		title: "Master",
		key: ROUTES.MASTER,
		path: ROUTES.MASTER,
		breadcrumb: ["Master"],
		protect: {
			must: ROLES.ADMIN
		}
	},
	{
		title: "Information",
		key: ROUTES.INFORMATION,
		path: ROUTES.INFORMATION,
		breadcrumb: ["Information"],
		protect: {
			must: ROLES.ADMIN
		}
	},
	{
		classname: "menu_right",
		key: ROUTES.USER_SETTING,
		path: ROUTES.USER_SETTING,
		breadcrumb: ["User Setting"],
		protect: {
			role: ROLES.USER
		}
	},
]

export const ROLE_LEVEL = {
	ADMIN: 100,
	USER: 10
}

export const LABELS = {
	MASTER_DATA: "master_data",
	TRAINING_DATA: "training_data",
	DATA_PREDICT: "data_predict",
	PREDICT_RESULT: "predict_result",
}

export const DETAIL_TABS = {
	STATISTICS: "Statistics Tab",
	DATA_VIEW: "Data View",
	CORRELATION: "Correlation Tab",
	SPLIT: "Split",
	CALCULATION: "Calculation",
	SELECT_COLUMN: "Select Column",
	TIME_SERIES: "Time Series Beta",
	WEATHER: "Weather Beta",
	WEATHER_FORECAST: "Weather Forecast",
	MERGE: "Merge"
}

export const MODEL_STATUS = [
	{
		color: "#ffac41",
		title: "Training"
	},
	{
		color: "#21bf73",
		title: "Trained"
	},
	{
		color: "#d63447",
		title: "Error"
	},
	{
		color: "#939598",
		title: "Stanby"
	}
]

export const STATUS_USER = {
	PENDING: "#ffac41",
	ACTIVE: "#21bf73",
	BLOCK: "#d63447"
}

export const WEATER_DATA_LABEL = [
	"気圧 (平均)",
	"海面気圧 (平均)",
	"降水量 (合計)",
	"最大降水量 (1時間)",
	"最大降水量 (10分間)",
	"最高気温",
	"最低気温",
	"平均気温",
	"湿度 (平均)",
	"湿度 (最小)",
	"平均風速",
	"最大風速",
	"最大風速 (風向)",
	"最大瞬間風速 ",
	"最大瞬間風速 (風向)",
	"日照時間",
	"降水量",
	"降雪量",
	"最深積雪",
	"天気 (日中)",
	"天気 (夜間)",
]

export const PROVINCE_PARAMS = [
	"気圧 (平均)",
	"海面気圧 (平均)",
	"降水量 (合計)",
	"最大降水量 (1時間)",
	"最大降水量 (10分間)",
	"平均気温",
	"最高気温",
	"最低気温",
	"湿度 (平均)",
	"湿度 (最小)",
	"平均風速",
	"最大風速",
	"最大風速 (風向)",
	"最大瞬間風速",
	"最大瞬間風速 (風向)",
	"日照時間",
	"降雪量",
	"最深積雪",
	"天気 (日中)",
	"天気 (夜間)"
]

export const DISTRIC_PARAMS = [
	"降水量 (合計)",
	"最大降水量 (1時間)",
	"最大降水量 (10分間)",
	"平均気温",
	"最高気温",
	"最低気温",
	"平均風速",
	"最大風速",
	"最大風速 (風向)",
	"最大瞬間風速",
	"最大瞬間風速 (風向)",
	"最多風向",
	"日照時間",
	"降雪量",
	"最深積雪"
]

export const FORECASTPARAMS = ["weather_code", "highest_temp", "minimum_temp"]

export const PROVINCES_DISTRICTS = [
	{
		"prec_no": "11",
		"perfecture_en": "Hokkaido",
		"perfecture_jp": "北海道",
		"station_jp": "稚内",
		"is_main_station": true,
		"label_en": "Wakkanai",
		"label_jp": "稚内",
		"block_no": "47401"
	},
	{
		"prec_no": "12",
		"perfecture_en": "Hokkaido",
		"perfecture_jp": "北海道",
		"station_jp": "上川総合振興局",
		"is_main_station": true,
		"label_en": "Asahikawa",
		"label_jp": "旭川",
		"block_no": "47407"
	},
	{
		"prec_no": "13",
		"perfecture_en": "Hokkaido",
		"perfecture_jp": "北海道",
		"station_jp": "留萌振興局",
		"is_main_station": true,
		"label_en": "Rumoi",
		"label_jp": "留萌",
		"block_no": "47406"
	},
	{
		"prec_no": "13",
		"perfecture_en": "Hokkaido",
		"perfecture_jp": "北海道",
		"station_jp": "留萌振興局",
		"is_main_station": true,
		"label_en": "Haboro",
		"label_jp": "羽幌",
		"block_no": "47404"
	},
	{
		"prec_no": "14",
		"perfecture_en": "Hokkaido",
		"perfecture_jp": "北海道",
		"station_jp": "石狩振興局",
		"is_main_station": true,
		"label_en": "Sapporo",
		"label_jp": "札幌",
		"block_no": "47412"
	},
	{
		"prec_no": "15",
		"perfecture_en": "Hokkaido",
		"perfecture_jp": "北海道",
		"station_jp": "空知総合振興局",
		"is_main_station": true,
		"label_en": "Iwamizawa",
		"label_jp": "岩見沢",
		"block_no": "47413"
	},
	{
		"prec_no": "16",
		"perfecture_en": "Hokkaido",
		"perfecture_jp": "北海道",
		"station_jp": "後志総合振興局",
		"is_main_station": true,
		"label_en": "Kutchan",
		"label_jp": "倶知安",
		"block_no": "47433"
	},
	{
		"prec_no": "16",
		"perfecture_en": "Hokkaido",
		"perfecture_jp": "北海道",
		"station_jp": "後志総合振興局",
		"is_main_station": true,
		"label_en": "Otaru",
		"label_jp": "小樽",
		"block_no": "47411"
	},
	{
		"prec_no": "16",
		"perfecture_en": "Hokkaido",
		"perfecture_jp": "北海道",
		"station_jp": "後志総合振興局",
		"is_main_station": true,
		"label_en": "Suttsu",
		"label_jp": "寿都",
		"block_no": "47421"
	},
	{
		"prec_no": "17",
		"perfecture_en": "Hokkaido",
		"perfecture_jp": "北海道",
		"station_jp": "オホーツク総合振興局",
		"is_main_station": true,
		"label_en": "Abashiri",
		"label_jp": "網走",
		"block_no": "47409"
	},
	{
		"prec_no": "17",
		"perfecture_en": "Hokkaido",
		"perfecture_jp": "北海道",
		"station_jp": "オホーツク総合振興局",
		"is_main_station": true,
		"label_en": "Mombetsu",
		"label_jp": "紋別",
		"block_no": "47435"
	},
	{
		"prec_no": "18",
		"perfecture_en": "Hokkaido",
		"perfecture_jp": "北海道",
		"station_jp": "根室振興局",
		"is_main_station": true,
		"label_en": "Nemuro",
		"label_jp": "根室",
		"block_no": "47420"
	},
	{
		"prec_no": "19",
		"perfecture_en": "Hokkaido",
		"perfecture_jp": "北海道",
		"station_jp": "釧路総合振興局",
		"is_main_station": true,
		"label_en": "Kushiro",
		"label_jp": "釧路",
		"block_no": "47418"
	},
	{
		"prec_no": "20",
		"perfecture_en": "Hokkaido",
		"perfecture_jp": "北海道",
		"station_jp": "十勝総合振興局",
		"is_main_station": true,
		"label_en": "Obihiro",
		"label_jp": "帯広",
		"block_no": "47417"
	},
	{
		"prec_no": "20",
		"perfecture_en": "Hokkaido",
		"perfecture_jp": "北海道",
		"station_jp": "十勝総合振興局",
		"is_main_station": true,
		"label_en": "Hiro",
		"label_jp": "広尾",
		"block_no": "47440"
	},
	{
		"prec_no": "21",
		"perfecture_en": "Hokkaido",
		"perfecture_jp": "北海道",
		"station_jp": "胆振総合振興局",
		"is_main_station": true,
		"label_en": "Tomakomai",
		"label_jp": "苫小牧",
		"block_no": "47424"
	},
	{
		"prec_no": "21",
		"perfecture_en": "Hokkaido",
		"perfecture_jp": "北海道",
		"station_jp": "胆振総合振興局",
		"is_main_station": true,
		"label_en": "Muroran",
		"label_jp": "室蘭",
		"block_no": "47423"
	},
	{
		"prec_no": "22",
		"perfecture_en": "Hokkaido",
		"perfecture_jp": "北海道",
		"station_jp": "日高振興局",
		"is_main_station": true,
		"label_en": "Urakawa",
		"label_jp": "浦河",
		"block_no": "47426"
	},
	{
		"prec_no": "23",
		"perfecture_en": "Hokkaido",
		"perfecture_jp": "北海道",
		"station_jp": "渡島総合振興局",
		"is_main_station": true,
		"label_en": "Hakodate",
		"label_jp": "函館",
		"block_no": "47430"
	},
	{
		"prec_no": "24",
		"perfecture_en": "Hokkaido",
		"perfecture_jp": "北海道",
		"station_jp": "檜山振興局",
		"is_main_station": true,
		"label_en": "Esashi",
		"label_jp": "江差",
		"block_no": "47428"
	},
	{
		"prec_no": "31",
		"perfecture_en": "Aomori",
		"perfecture_jp": "青森県",
		"station_jp": "青森県",
		"is_main_station": true,
		"label_en": "Aomori",
		"label_jp": "青森",
		"block_no": "47575"
	},
	{
		"prec_no": "31",
		"perfecture_en": "Aomori",
		"perfecture_jp": "青森県",
		"station_jp": "青森県",
		"is_main_station": true,
		"label_en": "Hachinohe",
		"label_jp": "八戸",
		"block_no": "47581"
	},
	{
		"prec_no": "31",
		"perfecture_en": "Aomori",
		"perfecture_jp": "青森県",
		"station_jp": "青森県",
		"is_main_station": true,
		"label_en": "Fukaura",
		"label_jp": "深浦",
		"block_no": "47574"
	},
	{
		"prec_no": "32",
		"perfecture_en": "Akita",
		"perfecture_jp": "秋田県",
		"station_jp": "秋田県",
		"is_main_station": true,
		"label_en": "Akita",
		"label_jp": "秋田",
		"block_no": "47582"
	},
	{
		"prec_no": "33",
		"perfecture_en": "Iwate",
		"perfecture_jp": "岩手県",
		"station_jp": "岩手県",
		"is_main_station": true,
		"label_en": "Morioka",
		"label_jp": "盛岡",
		"block_no": "47584"
	},
	{
		"prec_no": "33",
		"perfecture_en": "Iwate",
		"perfecture_jp": "岩手県",
		"station_jp": "岩手県",
		"is_main_station": true,
		"label_en": "Ofunato",
		"label_jp": "大船渡",
		"block_no": "47512"
	},
	{
		"prec_no": "33",
		"perfecture_en": "Iwate",
		"perfecture_jp": "岩手県",
		"station_jp": "岩手県",
		"is_main_station": true,
		"label_en": "Miyako",
		"label_jp": "宮古",
		"block_no": "47585"
	},
	{
		"prec_no": "34",
		"perfecture_en": "Miyagi",
		"perfecture_jp": "宮城県",
		"station_jp": "宮城県",
		"is_main_station": true,
		"label_en": "Sendai",
		"label_jp": "仙台",
		"block_no": "47590"
	},
	{
		"prec_no": "34",
		"perfecture_en": "Miyagi",
		"perfecture_jp": "宮城県",
		"station_jp": "宮城県",
		"is_main_station": true,
		"label_en": "Ishinomaki",
		"label_jp": "石巻",
		"block_no": "47592"
	},
	{
		"prec_no": "35",
		"perfecture_en": "Yamagata",
		"perfecture_jp": "山形県",
		"station_jp": "山形県",
		"is_main_station": true,
		"label_en": "Yamagata",
		"label_jp": "山形",
		"block_no": "47588"
	},
	{
		"prec_no": "35",
		"perfecture_en": "Yamagata",
		"perfecture_jp": "山形県",
		"station_jp": "山形県",
		"is_main_station": true,
		"label_en": "Shinjo",
		"label_jp": "新庄",
		"block_no": "47520"
	},
	{
		"prec_no": "35",
		"perfecture_en": "Yamagata",
		"perfecture_jp": "山形県",
		"station_jp": "山形県",
		"is_main_station": true,
		"label_en": "Sakata",
		"label_jp": "酒田",
		"block_no": "47587"
	},
	{
		"prec_no": "36",
		"perfecture_en": "Fukushima",
		"perfecture_jp": "福島県",
		"station_jp": "福島県",
		"is_main_station": true,
		"label_en": "Fukushima",
		"label_jp": "福島",
		"block_no": "47595"
	},
	{
		"prec_no": "36",
		"perfecture_en": "Fukushima",
		"perfecture_jp": "福島県",
		"station_jp": "福島県",
		"is_main_station": true,
		"label_en": "Wakamatsu",
		"label_jp": "若松",
		"block_no": "47570"
	},
	{
		"prec_no": "36",
		"perfecture_en": "Fukushima",
		"perfecture_jp": "福島県",
		"station_jp": "福島県",
		"is_main_station": true,
		"label_en": "Shirakawa",
		"label_jp": "白河",
		"block_no": "47597"
	},
	{
		"prec_no": "36",
		"perfecture_en": "Fukushima",
		"perfecture_jp": "福島県",
		"station_jp": "福島県",
		"is_main_station": true,
		"label_en": "Onahama",
		"label_jp": "小名浜",
		"block_no": "47598"
	},
	{
		"prec_no": "40",
		"perfecture_en": "Ibaraki",
		"perfecture_jp": "茨城県",
		"station_jp": "茨城県",
		"is_main_station": true,
		"label_en": "Tsukuba",
		"label_jp": "つくば（館野）",
		"block_no": "47646"
	},
	{
		"prec_no": "40",
		"perfecture_en": "Ibaraki",
		"perfecture_jp": "茨城県",
		"station_jp": "茨城県",
		"is_main_station": true,
		"label_en": "Mito",
		"label_jp": "水戸",
		"block_no": "47629"
	},
	{
		"prec_no": "41",
		"perfecture_en": "Tochigi",
		"perfecture_jp": "栃木県",
		"station_jp": "栃木県",
		"is_main_station": true,
		"label_en": "Utsunomiya",
		"label_jp": "宇都宮",
		"block_no": "47615"
	},
	{
		"prec_no": "41",
		"perfecture_en": "Tochigi",
		"perfecture_jp": "栃木県",
		"station_jp": "栃木県",
		"is_main_station": true,
		"label_en": "Nikko",
		"label_jp": "日光",
		"block_no": "47690"
	},
	{
		"prec_no": "42",
		"perfecture_en": "Gunma",
		"perfecture_jp": "群馬県",
		"station_jp": "群馬県",
		"is_main_station": true,
		"label_en": "Maebashi",
		"label_jp": "前橋",
		"block_no": "47624"
	},
	{
		"prec_no": "43",
		"perfecture_en": "Saitama",
		"perfecture_jp": "埼玉県",
		"station_jp": "埼玉県",
		"is_main_station": true,
		"label_en": "Kumagaya",
		"label_jp": "熊谷",
		"block_no": "47626"
	},
	{
		"prec_no": "43",
		"perfecture_en": "Saitama",
		"perfecture_jp": "埼玉県",
		"station_jp": "埼玉県",
		"is_main_station": true,
		"label_en": "Chichibu",
		"label_jp": "秩父",
		"block_no": "47641"
	},
	{
		"prec_no": "43",
		"perfecture_en": "Saitama",
		"perfecture_jp": "埼玉県",
		"station_jp": "埼玉県",
		"is_main_station": false,
		"label_en": "Koshigaya",
		"label_jp": "越谷",
		"block_no": "0364"
	},
	{
		"prec_no": "43",
		"perfecture_en": "Saitama",
		"perfecture_jp": "埼玉県",
		"station_jp": "埼玉県",
		"is_main_station": false,
		"label_en": "Tokorozawa",
		"label_jp": "所沢",
		"block_no": "1070"
	},
	{
		"prec_no": "43",
		"perfecture_en": "Saitama",
		"perfecture_jp": "埼玉県",
		"station_jp": "埼玉県",
		"is_main_station": false,
		"label_en": "Saitama",
		"label_jp": "さいたま",
		"block_no": "0363"
	},
	{
		"prec_no": "44",
		"perfecture_en": "Tokyo",
		"perfecture_jp": "東京都",
		"station_jp": "東京都",
		"is_main_station": true,
		"label_en": "Tokyo",
		"label_jp": "東京",
		"block_no": "47662"
	},
	{
		"prec_no": "44",
		"perfecture_en": "Tokyo",
		"perfecture_jp": "東京都",
		"station_jp": "東京都",
		"is_main_station": false,
		"label_en": "Nerima",
		"label_jp": "練馬",
		"block_no": "1002"
	},
	{
		"prec_no": "44",
		"perfecture_en": "Tokyo",
		"perfecture_jp": "東京都",
		"station_jp": "東京都",
		"is_main_station": false,
		"label_en": "Edogawa-Rinkai",
		"label_jp": "江戸川臨海",
		"block_no": "0370"
	},
	{
		"prec_no": "44",
		"perfecture_en": "Tokyo",
		"perfecture_jp": "東京都",
		"station_jp": "東京都",
		"is_main_station": false,
		"label_en": "Fuchu",
		"label_jp": "府中",
		"block_no": "1133"
	},
	{
		"prec_no": "44",
		"perfecture_en": "Tokyo",
		"perfecture_jp": "東京都",
		"station_jp": "東京都",
		"is_main_station": false,
		"label_en": "Hachioji",
		"label_jp": "八王子",
		"block_no": "0366"
	},
	{
		"prec_no": "45",
		"perfecture_en": "Chiba",
		"perfecture_jp": "千葉県",
		"station_jp": "千葉県",
		"is_main_station": true,
		"label_en": "Chiba",
		"label_jp": "千葉",
		"block_no": "47682"
	},
	{
		"prec_no": "45",
		"perfecture_en": "Chiba",
		"perfecture_jp": "千葉県",
		"station_jp": "千葉県",
		"is_main_station": false,
		"label_en": "Funabashi",
		"label_jp": "船橋",
		"block_no": "1236"
	},
	{
		"prec_no": "45",
		"perfecture_en": "Chiba",
		"perfecture_jp": "千葉県",
		"station_jp": "千葉県",
		"is_main_station": true,
		"label_en": "Choshi",
		"label_jp": "銚子",
		"block_no": "47648"
	},
	{
		"prec_no": "45",
		"perfecture_en": "Chiba",
		"perfecture_jp": "千葉県",
		"station_jp": "千葉県",
		"is_main_station": true,
		"label_en": "Tateyama",
		"label_jp": "館山",
		"block_no": "47672"
	},
	{
		"prec_no": "45",
		"perfecture_en": "Chiba",
		"perfecture_jp": "千葉県",
		"station_jp": "千葉県",
		"is_main_station": true,
		"label_en": "Katsuura",
		"label_jp": "勝浦",
		"block_no": "47674"
	},
	{
		"prec_no": "46",
		"perfecture_en": "Kanagawa",
		"perfecture_jp": "神奈川県",
		"station_jp": "神奈川県",
		"is_main_station": true,
		"label_en": "Yokohama",
		"label_jp": "横浜",
		"block_no": "47670"
	},
	{
		"prec_no": "46",
		"perfecture_en": "Kanagawa",
		"perfecture_jp": "神奈川県",
		"station_jp": "神奈川県",
		"is_main_station": false,
		"label_en": "Ebina",
		"label_jp": "海老名",
		"block_no": "0388"
	},
	{
		"prec_no": "46",
		"perfecture_en": "Kanagawa",
		"perfecture_jp": "神奈川県",
		"station_jp": "神奈川県",
		"is_main_station": false,
		"label_en": "Tsujido",
		"label_jp": "辻堂",
		"block_no": "false"
	},
	{
		"prec_no": "46",
		"perfecture_en": "Kanagawa",
		"perfecture_jp": "神奈川県",
		"station_jp": "神奈川県",
		"is_main_station": false,
		"label_en": "Miura",
		"label_jp": "三浦",
		"block_no": "0392"
	},
	{
		"prec_no": "46",
		"perfecture_en": "Kanagawa",
		"perfecture_jp": "神奈川県",
		"station_jp": "神奈川県",
		"is_main_station": false,
		"label_en": "Odawara",
		"label_jp": "小田原",
		"block_no": "1008"
	},
	{
		"prec_no": "48",
		"perfecture_en": "Nagano",
		"perfecture_jp": "長野県",
		"station_jp": "長野県",
		"is_main_station": true,
		"label_en": "Nagano",
		"label_jp": "長野",
		"block_no": "47610"
	},
	{
		"prec_no": "48",
		"perfecture_en": "Nagano",
		"perfecture_jp": "長野県",
		"station_jp": "長野県",
		"is_main_station": true,
		"label_en": "Matsumoto",
		"label_jp": "松本",
		"block_no": "47618"
	},
	{
		"prec_no": "48",
		"perfecture_en": "Nagano",
		"perfecture_jp": "長野県",
		"station_jp": "長野県",
		"is_main_station": true,
		"label_en": "Suwa",
		"label_jp": "諏訪",
		"block_no": "47620"
	},
	{
		"prec_no": "48",
		"perfecture_en": "Nagano",
		"perfecture_jp": "長野県",
		"station_jp": "長野県",
		"is_main_station": true,
		"label_en": "Karuizawa",
		"label_jp": "軽井沢",
		"block_no": "47622"
	},
	{
		"prec_no": "48",
		"perfecture_en": "Nagano",
		"perfecture_jp": "長野県",
		"station_jp": "長野県",
		"is_main_station": true,
		"label_en": "Iida",
		"label_jp": "飯田",
		"block_no": "47637"
	},
	{
		"prec_no": "49",
		"perfecture_en": "Yamanashi",
		"perfecture_jp": "山梨県",
		"station_jp": "山梨県",
		"is_main_station": true,
		"label_en": "Kofu",
		"label_jp": "甲府",
		"block_no": "47638"
	},
	{
		"prec_no": "49",
		"perfecture_en": "Yamanashi",
		"perfecture_jp": "山梨県",
		"station_jp": "山梨県",
		"is_main_station": true,
		"label_en": "Kawaguchiko",
		"label_jp": "河口湖",
		"block_no": "47640"
	},
	{
		"prec_no": "49",
		"perfecture_en": "Yamanashi",
		"perfecture_jp": "山梨県",
		"station_jp": "山梨県",
		"is_main_station": true,
		"label_en": "Fujisan",
		"label_jp": "富士山",
		"block_no": "47639"
	},
	{
		"prec_no": "50",
		"perfecture_en": "Shizuoka",
		"perfecture_jp": "静岡県",
		"station_jp": "静岡県",
		"is_main_station": true,
		"label_en": "Hamamatsu",
		"label_jp": "浜松",
		"block_no": "47654"
	},
	{
		"prec_no": "50",
		"perfecture_en": "Shizuoka",
		"perfecture_jp": "静岡県",
		"station_jp": "静岡県",
		"is_main_station": true,
		"label_en": "Omaezaki",
		"label_jp": "御前崎",
		"block_no": "47655"
	},
	{
		"prec_no": "50",
		"perfecture_en": "Shizuoka",
		"perfecture_jp": "静岡県",
		"station_jp": "静岡県",
		"is_main_station": true,
		"label_en": "Shizuoka",
		"label_jp": "静岡",
		"block_no": "47656"
	},
	{
		"prec_no": "50",
		"perfecture_en": "Shizuoka",
		"perfecture_jp": "静岡県",
		"station_jp": "静岡県",
		"is_main_station": true,
		"label_en": "Mishima",
		"label_jp": "三島",
		"block_no": "47657"
	},
	{
		"prec_no": "50",
		"perfecture_en": "Shizuoka",
		"perfecture_jp": "静岡県",
		"station_jp": "静岡県",
		"is_main_station": true,
		"label_en": "Irozaki",
		"label_jp": "石廊崎",
		"block_no": "47666"
	},
	{
		"prec_no": "50",
		"perfecture_en": "Shizuoka",
		"perfecture_jp": "静岡県",
		"station_jp": "静岡県",
		"is_main_station": true,
		"label_en": "Ajiro",
		"label_jp": "網代",
		"block_no": "47668"
	},
	{
		"prec_no": "51",
		"perfecture_en": "Aichi",
		"perfecture_jp": "愛知県",
		"station_jp": "愛知県",
		"is_main_station": true,
		"label_en": "Nagoya",
		"label_jp": "名古屋",
		"block_no": "47636"
	},
	{
		"prec_no": "51",
		"perfecture_en": "Aichi",
		"perfecture_jp": "愛知県",
		"station_jp": "愛知県",
		"is_main_station": true,
		"label_en": "Irago",
		"label_jp": "伊良湖",
		"block_no": "47653"
	},
	{
		"prec_no": "52",
		"perfecture_en": "Gifu",
		"perfecture_jp": "岐阜県",
		"station_jp": "岐阜県",
		"is_main_station": true,
		"label_en": "Gifu",
		"label_jp": "岐阜",
		"block_no": "47632"
	},
	{
		"prec_no": "52",
		"perfecture_en": "Gifu",
		"perfecture_jp": "岐阜県",
		"station_jp": "岐阜県",
		"is_main_station": true,
		"label_en": "Takayama",
		"label_jp": "高山",
		"block_no": "47617"
	},
	{
		"prec_no": "53",
		"perfecture_en": "Mie",
		"perfecture_jp": "三重県",
		"station_jp": "三重県",
		"is_main_station": true,
		"label_en": "Tsu",
		"label_jp": "津",
		"block_no": "47651"
	},
	{
		"prec_no": "53",
		"perfecture_en": "Mie",
		"perfecture_jp": "三重県",
		"station_jp": "三重県",
		"is_main_station": true,
		"label_en": "Yokkaichi",
		"label_jp": "四日市",
		"block_no": "47684"
	},
	{
		"prec_no": "53",
		"perfecture_en": "Mie",
		"perfecture_jp": "三重県",
		"station_jp": "三重県",
		"is_main_station": true,
		"label_en": "Ueno",
		"label_jp": "上野",
		"block_no": "47649"
	},
	{
		"prec_no": "53",
		"perfecture_en": "Mie",
		"perfecture_jp": "三重県",
		"station_jp": "三重県",
		"is_main_station": true,
		"label_en": "Owase",
		"label_jp": "尾鷲",
		"block_no": "47663"
	},
	{
		"prec_no": "54",
		"perfecture_en": "Nigata",
		"perfecture_jp": "新潟県",
		"station_jp": "新潟県",
		"is_main_station": true,
		"label_en": "Aikawa",
		"label_jp": "相川",
		"block_no": "47602"
	},
	{
		"prec_no": "54",
		"perfecture_en": "Nigata",
		"perfecture_jp": "新潟県",
		"station_jp": "新潟県",
		"is_main_station": true,
		"label_en": "Niigata",
		"label_jp": "新潟",
		"block_no": "47604"
	},
	{
		"prec_no": "54",
		"perfecture_en": "Nigata",
		"perfecture_jp": "新潟県",
		"station_jp": "新潟県",
		"is_main_station": true,
		"label_en": "Takada",
		"label_jp": "高田",
		"block_no": "47612"
	},
	{
		"prec_no": "55",
		"perfecture_en": "Toyama",
		"perfecture_jp": "富山県",
		"station_jp": "富山県",
		"is_main_station": true,
		"label_en": "Fushiki",
		"label_jp": "伏木",
		"block_no": "47606"
	},
	{
		"prec_no": "55",
		"perfecture_en": "Toyama",
		"perfecture_jp": "富山県",
		"station_jp": "富山県",
		"is_main_station": true,
		"label_en": "Toyama",
		"label_jp": "富山",
		"block_no": "47607"
	},
	{
		"prec_no": "56",
		"perfecture_en": "Ishikawa",
		"perfecture_jp": "石川県",
		"station_jp": "石川県",
		"is_main_station": true,
		"label_en": "Wajima",
		"label_jp": "輪島",
		"block_no": "47600"
	},
	{
		"prec_no": "56",
		"perfecture_en": "Ishikawa",
		"perfecture_jp": "石川県",
		"station_jp": "石川県",
		"is_main_station": true,
		"label_en": "Kanazawa",
		"label_jp": "金沢",
		"block_no": "47605"
	},
	{
		"prec_no": "57",
		"perfecture_en": "Fukui",
		"perfecture_jp": "福井県",
		"station_jp": "福井県",
		"is_main_station": true,
		"label_en": "Fukui",
		"label_jp": "福井",
		"block_no": "47616"
	},
	{
		"prec_no": "57",
		"perfecture_en": "Fukui",
		"perfecture_jp": "福井県",
		"station_jp": "福井県",
		"is_main_station": true,
		"label_en": "Tsuruga",
		"label_jp": "敦賀",
		"block_no": "47631"
	},
	{
		"prec_no": "60",
		"perfecture_en": "Shiga",
		"perfecture_jp": "滋賀県",
		"station_jp": "滋賀県",
		"is_main_station": true,
		"label_en": "Hikone",
		"label_jp": "彦根",
		"block_no": "47761"
	},
	{
		"prec_no": "60",
		"perfecture_en": "Shiga",
		"perfecture_jp": "滋賀県",
		"station_jp": "滋賀県",
		"is_main_station": true,
		"label_en": "Ibukiyama",
		"label_jp": "伊吹山",
		"block_no": "47751"
	},
	{
		"prec_no": "61",
		"perfecture_en": "Kyoto",
		"perfecture_jp": "京都府",
		"station_jp": "京都府",
		"is_main_station": true,
		"label_en": "Maizuru",
		"label_jp": "舞鶴",
		"block_no": "47750"
	},
	{
		"prec_no": "61",
		"perfecture_en": "Kyoto",
		"perfecture_jp": "京都府",
		"station_jp": "京都府",
		"is_main_station": true,
		"label_en": "Kyoto",
		"label_jp": "京都",
		"block_no": "47759"
	},
	{
		"prec_no": "62",
		"perfecture_en": "Osaka",
		"perfecture_jp": "大阪府",
		"station_jp": "大阪府",
		"is_main_station": true,
		"label_en": "Osaka",
		"label_jp": "大阪",
		"block_no": "47772"
	},
	{
		"prec_no": "62",
		"perfecture_en": "Osaka",
		"perfecture_jp": "大阪府",
		"station_jp": "大阪府",
		"is_main_station": false,
		"label_en": "Toyonaka",
		"label_jp": "豊中",
		"block_no": "0602"
	},
	{
		"prec_no": "62",
		"perfecture_en": "Osaka",
		"perfecture_jp": "大阪府",
		"station_jp": "大阪府",
		"is_main_station": false,
		"label_en": "Maikata",
		"label_jp": "枚方",
		"block_no": "1065"
	},
	{
		"prec_no": "62",
		"perfecture_en": "Osaka",
		"perfecture_jp": "大阪府",
		"station_jp": "大阪府",
		"is_main_station": false,
		"label_en": "Sakai",
		"label_jp": "堺",
		"block_no": "1062"
	},
	{
		"prec_no": "63",
		"perfecture_en": "Hyogo",
		"perfecture_jp": "兵庫県",
		"station_jp": "兵庫県",
		"is_main_station": false,
		"label_en": "Toyoka",
		"label_jp": "豊岡",
		"block_no": "47747"
	},
	{
		"prec_no": "63",
		"perfecture_en": "Hyogo",
		"perfecture_jp": "兵庫県",
		"station_jp": "兵庫県",
		"is_main_station": false,
		"label_en": "Himeji",
		"label_jp": "姫路",
		"block_no": "47769"
	},
	{
		"prec_no": "63",
		"perfecture_en": "Hyogo",
		"perfecture_jp": "兵庫県",
		"station_jp": "兵庫県",
		"is_main_station": true,
		"label_en": "Kobe",
		"label_jp": "神戸",
		"block_no": "47770"
	},
	{
		"prec_no": "63",
		"perfecture_en": "Hyogo",
		"perfecture_jp": "兵庫県",
		"station_jp": "兵庫県",
		"is_main_station": false,
		"label_en": "Sumoto",
		"label_jp": "洲本",
		"block_no": "47776"
	},
	{
		"prec_no": "64",
		"perfecture_en": "Nara",
		"perfecture_jp": "奈良県",
		"station_jp": "奈良県",
		"is_main_station": true,
		"label_en": "Nara",
		"label_jp": "奈良",
		"block_no": "47780"
	},
	{
		"prec_no": "65",
		"perfecture_en": "Wakayama",
		"perfecture_jp": "和歌山県",
		"station_jp": "和歌山県",
		"is_main_station": true,
		"label_en": "Wakayama",
		"label_jp": "和歌山",
		"block_no": "47777"
	},
	{
		"prec_no": "65",
		"perfecture_en": "Wakayama",
		"perfecture_jp": "和歌山県",
		"station_jp": "和歌山県",
		"is_main_station": true,
		"label_en": "Shionomisaki",
		"label_jp": "潮岬",
		"block_no": "47778"
	},
	{
		"prec_no": "66",
		"perfecture_en": "Okayama",
		"perfecture_jp": "岡山県",
		"station_jp": "岡山県",
		"is_main_station": true,
		"label_en": "Tsuyama",
		"label_jp": "津山",
		"block_no": "47756"
	},
	{
		"prec_no": "66",
		"perfecture_en": "Okayama",
		"perfecture_jp": "岡山県",
		"station_jp": "岡山県",
		"is_main_station": true,
		"label_en": "Okayama",
		"label_jp": "岡山",
		"block_no": "47768"
	},
	{
		"prec_no": "67",
		"perfecture_en": "Hiroshima",
		"perfecture_jp": "広島県",
		"station_jp": "広島県",
		"is_main_station": true,
		"label_en": "Hiroshima",
		"label_jp": "広島",
		"block_no": "47765"
	},
	{
		"prec_no": "67",
		"perfecture_en": "Hiroshima",
		"perfecture_jp": "広島県",
		"station_jp": "広島県",
		"is_main_station": true,
		"label_en": "Kure",
		"label_jp": "呉",
		"block_no": "47766"
	},
	{
		"prec_no": "67",
		"perfecture_en": "Hiroshima",
		"perfecture_jp": "広島県",
		"station_jp": "広島県",
		"is_main_station": true,
		"label_en": "Fukuyama",
		"label_jp": "福山",
		"block_no": "47767"
	},
	{
		"prec_no": "68",
		"perfecture_en": "Simane",
		"perfecture_jp": "島根県",
		"station_jp": "島根県",
		"is_main_station": true,
		"label_en": "Saigo",
		"label_jp": "西郷",
		"block_no": "47740"
	},
	{
		"prec_no": "68",
		"perfecture_en": "Simane",
		"perfecture_jp": "島根県",
		"station_jp": "島根県",
		"is_main_station": true,
		"label_en": "Matsue",
		"label_jp": "松江",
		"block_no": "47741"
	},
	{
		"prec_no": "68",
		"perfecture_en": "Shimane",
		"perfecture_jp": "島根県",
		"station_jp": "島根県",
		"is_main_station": true,
		"label_en": "Hamada",
		"label_jp": "浜田",
		"block_no": "47755"
	},
	{
		"prec_no": "69",
		"perfecture_en": "Tottori",
		"perfecture_jp": "鳥取県",
		"station_jp": "鳥取県",
		"is_main_station": true,
		"label_en": "Sakai",
		"label_jp": "境",
		"block_no": "47742"
	},
	{
		"prec_no": "69",
		"perfecture_en": "Tottori",
		"perfecture_jp": "鳥取県",
		"station_jp": "鳥取県",
		"is_main_station": true,
		"label_en": "Yonago",
		"label_jp": "米子",
		"block_no": "47744"
	},
	{
		"prec_no": "69",
		"perfecture_en": "Tottori",
		"perfecture_jp": "鳥取県",
		"station_jp": "鳥取県",
		"is_main_station": true,
		"label_en": "Tottori",
		"label_jp": "鳥取",
		"block_no": "47746"
	},
	{
		"prec_no": "71",
		"perfecture_en": "Tokushima",
		"perfecture_jp": "徳島県",
		"station_jp": "徳島県",
		"is_main_station": true,
		"label_en": "Tokushima",
		"label_jp": "徳島",
		"block_no": "47895"
	},
	{
		"prec_no": "71",
		"perfecture_en": "Tokushima",
		"perfecture_jp": "徳島県",
		"station_jp": "徳島県",
		"is_main_station": true,
		"label_en": "Tsurugisan",
		"label_jp": "剣山",
		"block_no": "47894"
	},
	{
		"prec_no": "72",
		"perfecture_en": "Kagawa",
		"perfecture_jp": "香川県",
		"station_jp": "香川県",
		"is_main_station": true,
		"label_en": "Tadotsu",
		"label_jp": "多度津",
		"block_no": "47890"
	},
	{
		"prec_no": "72",
		"perfecture_en": "Kagawa",
		"perfecture_jp": "香川県",
		"station_jp": "香川県",
		"is_main_station": true,
		"label_en": "Takamatsu",
		"label_jp": "高松",
		"block_no": "47891"
	},
	{
		"prec_no": "73",
		"perfecture_en": "Ehime",
		"perfecture_jp": "愛媛県",
		"station_jp": "愛媛県",
		"is_main_station": true,
		"label_en": "Matsuyama",
		"label_jp": "松山",
		"block_no": "47887"
	},
	{
		"prec_no": "73",
		"perfecture_en": "Ehime",
		"perfecture_jp": "愛媛県",
		"station_jp": "愛媛県",
		"is_main_station": true,
		"label_en": "Uwajima",
		"label_jp": "宇和島",
		"block_no": "47892"
	},
	{
		"prec_no": "74",
		"perfecture_en": "Kochi",
		"perfecture_jp": "高知県",
		"station_jp": "高知県",
		"is_main_station": true,
		"label_en": "Kochi",
		"label_jp": "高知",
		"block_no": "47893"
	},
	{
		"prec_no": "74",
		"perfecture_en": "Kochi",
		"perfecture_jp": "高知県",
		"station_jp": "高知県",
		"is_main_station": true,
		"label_en": "Sukumo",
		"label_jp": "宿毛",
		"block_no": "47897"
	},
	{
		"prec_no": "74",
		"perfecture_en": "Kochi",
		"perfecture_jp": "高知県",
		"station_jp": "高知県",
		"is_main_station": true,
		"label_en": "Shimizu",
		"label_jp": "清水",
		"block_no": "47898"
	},
	{
		"prec_no": "74",
		"perfecture_en": "Kochi",
		"perfecture_jp": "高知県",
		"station_jp": "高知県",
		"is_main_station": true,
		"label_en": "Murotomisaki",
		"label_jp": "室戸岬",
		"block_no": "47899"
	},
	{
		"prec_no": "81",
		"perfecture_en": "Yamaguchi",
		"perfecture_jp": "山口県",
		"station_jp": "山口県",
		"is_main_station": true,
		"label_en": "Hagi",
		"label_jp": "萩",
		"block_no": "47754"
	},
	{
		"prec_no": "81",
		"perfecture_en": "Yamaguchi",
		"perfecture_jp": "山口県",
		"station_jp": "山口県",
		"is_main_station": true,
		"label_en": "Shimonoseki",
		"label_jp": "下関",
		"block_no": "47762"
	},
	{
		"prec_no": "81",
		"perfecture_en": "Yamaguchi",
		"perfecture_jp": "山口県",
		"station_jp": "山口県",
		"is_main_station": true,
		"label_en": "Yamaguchi",
		"label_jp": "山口",
		"block_no": "47784"
	},
	{
		"prec_no": "82",
		"perfecture_en": "Fukuoka",
		"perfecture_jp": "福岡県",
		"station_jp": "福岡県",
		"is_main_station": true,
		"label_en": "Fukuoka",
		"label_jp": "福岡",
		"block_no": "47807"
	},
	{
		"prec_no": "82",
		"perfecture_en": "Fukuoka",
		"perfecture_jp": "福岡県",
		"station_jp": "福岡県",
		"is_main_station": true,
		"label_en": "Iizuka",
		"label_jp": "飯塚",
		"block_no": "47809"
	},
	{
		"prec_no": "83",
		"perfecture_en": "Oita",
		"perfecture_jp": "大分県",
		"station_jp": "大分県",
		"is_main_station": true,
		"label_en": "Hita",
		"label_jp": "日田",
		"block_no": "47814"
	},
	{
		"prec_no": "83",
		"perfecture_en": "Oita",
		"perfecture_jp": "大分県",
		"station_jp": "大分県",
		"is_main_station": true,
		"label_en": "Oita",
		"label_jp": "大分",
		"block_no": "47815"
	},
	{
		"prec_no": "84",
		"perfecture_en": "Nagasaki",
		"perfecture_jp": "長崎県",
		"station_jp": "長崎県",
		"is_main_station": true,
		"label_en": "Izuhara",
		"label_jp": "厳原",
		"block_no": "47800"
	},
	{
		"prec_no": "84",
		"perfecture_en": "Nagasaki",
		"perfecture_jp": "長崎県",
		"station_jp": "長崎県",
		"is_main_station": true,
		"label_en": "Hirado",
		"label_jp": "平戸",
		"block_no": "47805"
	},
	{
		"prec_no": "84",
		"perfecture_en": "Nagasaki",
		"perfecture_jp": "長崎県",
		"station_jp": "長崎県",
		"is_main_station": true,
		"label_en": "Sasebo",
		"label_jp": "佐世保",
		"block_no": "47812"
	},
	{
		"prec_no": "84",
		"perfecture_en": "Nagasaki",
		"perfecture_jp": "長崎県",
		"station_jp": "長崎県",
		"is_main_station": true,
		"label_en": "Nagasaki",
		"label_jp": "長崎",
		"block_no": "47817"
	},
	{
		"prec_no": "84",
		"perfecture_en": "Nagasaki",
		"perfecture_jp": "長崎県",
		"station_jp": "長崎県",
		"is_main_station": true,
		"label_en": "Unzendake",
		"label_jp": "雲仙岳",
		"block_no": "47818"
	},
	{
		"prec_no": "84",
		"perfecture_en": "Nagasaki",
		"perfecture_jp": "長崎県",
		"station_jp": "長崎県",
		"is_main_station": true,
		"label_en": "Fukue",
		"label_jp": "福江",
		"block_no": "47843"
	},
	{
		"prec_no": "85",
		"perfecture_en": "Saga",
		"perfecture_jp": "佐賀県",
		"station_jp": "佐賀県",
		"is_main_station": true,
		"label_en": "Saga",
		"label_jp": "佐賀",
		"block_no": "47813"
	},
	{
		"prec_no": "86",
		"perfecture_en": "Kumamoto",
		"perfecture_jp": "熊本県",
		"station_jp": "熊本県",
		"is_main_station": true,
		"label_en": "Kumamoto",
		"label_jp": "熊本",
		"block_no": "47819"
	},
	{
		"prec_no": "86",
		"perfecture_en": "Kumamoto",
		"perfecture_jp": "熊本県",
		"station_jp": "熊本県",
		"is_main_station": true,
		"label_en": "Hitoyoshi",
		"label_jp": "人吉",
		"block_no": "47824"
	},
	{
		"prec_no": "86",
		"perfecture_en": "Kumamoto",
		"perfecture_jp": "熊本県",
		"station_jp": "熊本県",
		"is_main_station": true,
		"label_en": "Ushibuka",
		"label_jp": "牛深",
		"block_no": "47838"
	},
	{
		"prec_no": "86",
		"perfecture_en": "Kumamoto",
		"perfecture_jp": "熊本県",
		"station_jp": "熊本県",
		"is_main_station": true,
		"label_en": "Asosan",
		"label_jp": "阿蘇山",
		"block_no": "47821"
	},
	{
		"prec_no": "87",
		"perfecture_en": "Miyazaki",
		"perfecture_jp": "宮崎県",
		"station_jp": "宮崎県",
		"is_main_station": true,
		"label_en": "Nobeoka",
		"label_jp": "延岡",
		"block_no": "47822"
	},
	{
		"prec_no": "87",
		"perfecture_en": "Miyazaki",
		"perfecture_jp": "宮崎県",
		"station_jp": "宮崎県",
		"is_main_station": true,
		"label_en": "Miyakonojo",
		"label_jp": "都城",
		"block_no": "47829"
	},
	{
		"prec_no": "87",
		"perfecture_en": "Miyazaki",
		"perfecture_jp": "宮崎県",
		"station_jp": "宮崎県",
		"is_main_station": true,
		"label_en": "Miyazaki",
		"label_jp": "宮崎",
		"block_no": "47830"
	},
	{
		"prec_no": "87",
		"perfecture_en": "Miyazaki",
		"perfecture_jp": "宮崎県",
		"station_jp": "宮崎県",
		"is_main_station": true,
		"label_en": "Aburatsu",
		"label_jp": "油津",
		"block_no": "47835"
	},
	{
		"prec_no": "88",
		"perfecture_en": "Kagoshima",
		"perfecture_jp": "鹿児島県",
		"station_jp": "鹿児島県",
		"is_main_station": true,
		"label_en": "Akune",
		"label_jp": "阿久根",
		"block_no": "47823"
	},
	{
		"prec_no": "88",
		"perfecture_en": "Kagoshima",
		"perfecture_jp": "鹿児島県",
		"station_jp": "鹿児島県",
		"is_main_station": true,
		"label_en": "Kagoshima",
		"label_jp": "鹿児島",
		"block_no": "47827"
	},
	{
		"prec_no": "88",
		"perfecture_en": "Kagoshima",
		"perfecture_jp": "鹿児島県",
		"station_jp": "鹿児島県",
		"is_main_station": true,
		"label_en": "Makurazaki",
		"label_jp": "枕崎",
		"block_no": "47831"
	},
	{
		"prec_no": "88",
		"perfecture_en": "Kagoshima",
		"perfecture_jp": "鹿児島県",
		"station_jp": "鹿児島県",
		"is_main_station": true,
		"label_en": "Yakushima",
		"label_jp": "屋久島",
		"block_no": "47836"
	},
	{
		"prec_no": "88",
		"perfecture_en": "Kagoshima",
		"perfecture_jp": "鹿児島県",
		"station_jp": "鹿児島県",
		"is_main_station": true,
		"label_en": "Tanegashima",
		"label_jp": "種子島",
		"block_no": "47837"
	},
	{
		"prec_no": "88",
		"perfecture_en": "Kagoshima",
		"perfecture_jp": "鹿児島県",
		"station_jp": "鹿児島県",
		"is_main_station": true,
		"label_en": "Naze",
		"label_jp": "名瀬",
		"block_no": "47909"
	},
	{
		"prec_no": "88",
		"perfecture_en": "Kagoshima",
		"perfecture_jp": "鹿児島県",
		"station_jp": "鹿児島県",
		"is_main_station": true,
		"label_en": "Okinoerabu",
		"label_jp": "沖永良部",
		"block_no": "47942"
	},
	{
		"prec_no": "91",
		"perfecture_en": "Okinawa",
		"perfecture_jp": "沖縄県",
		"station_jp": "沖縄県",
		"is_main_station": true,
		"label_en": "Yonagunizima",
		"label_jp": "与那国島",
		"block_no": "47912"
	},
	{
		"prec_no": "91",
		"perfecture_en": "Okinawa",
		"perfecture_jp": "沖縄県",
		"station_jp": "沖縄県",
		"is_main_station": true,
		"label_en": "Iriomote",
		"label_jp": "西表島",
		"block_no": "47917"
	},
	{
		"prec_no": "91",
		"perfecture_en": "Okinawa",
		"perfecture_jp": "沖縄県",
		"station_jp": "沖縄県",
		"is_main_station": true,
		"label_en": "Ishigakizima",
		"label_jp": "石垣島",
		"block_no": "47918"
	},
	{
		"prec_no": "91",
		"perfecture_en": "Okinawa",
		"perfecture_jp": "沖縄県",
		"station_jp": "沖縄県",
		"is_main_station": true,
		"label_en": "Miyakozima",
		"label_jp": "宮古島",
		"block_no": "47927"
	},
	{
		"prec_no": "91",
		"perfecture_en": "Okinawa",
		"perfecture_jp": "沖縄県",
		"station_jp": "沖縄県",
		"is_main_station": true,
		"label_en": "Kumezima",
		"label_jp": "久米島",
		"block_no": "47929"
	},
	{
		"prec_no": "91",
		"perfecture_en": "Okinawa",
		"perfecture_jp": "沖縄県",
		"station_jp": "沖縄県",
		"is_main_station": true,
		"label_en": "Naha",
		"label_jp": "那覇",
		"block_no": "47936"
	},
	{
		"prec_no": "91",
		"perfecture_en": "Okinawa",
		"perfecture_jp": "沖縄県",
		"station_jp": "沖縄県",
		"is_main_station": true,
		"label_en": "Nagoya",
		"label_jp": "名護",
		"block_no": "47940"
	},
	{
		"prec_no": "91",
		"perfecture_en": "Okinawa",
		"perfecture_jp": "沖縄県",
		"station_jp": "沖縄県",
		"is_main_station": true,
		"label_en": "Minamidaitozima",
		"label_jp": "南大東島",
		"block_no": "47945"
	},
	{
		"prec_no": "92",
		"perfecture_en": "Okinawa",
		"perfecture_jp": "沖縄県",
		"station_jp": "島尻郡のうち、南大東村及び北大東村",
		"is_main_station": true,
		"label_en": "-",
		"label_jp": "-",
		"block_no": "-"
	},
	{
		"prec_no": "93",
		"perfecture_en": "Okinawa",
		"perfecture_jp": "沖縄県",
		"station_jp": "宮古島市及び宮古郡",
		"is_main_station": true,
		"label_en": "-",
		"label_jp": "-",
		"block_no": "-"
	},
	{
		"prec_no": "94",
		"perfecture_en": "Okinawa",
		"perfecture_jp": "沖縄県",
		"station_jp": "石垣市及び八重山郡",
		"is_main_station": true,
		"label_en": "-",
		"label_jp": "-",
		"block_no": "-"
	}
]
export const PROVINCES_DISTRICTS_FORECAST = [{
	'name': '前橋',
	'enName': 'Gunma',
	'parent': {
		'name': '前橋',
		'enName': 'Gunma',
		'officeName': '前橋地方気象台',
		'parent': '010300',
		'children': ['100010', '100020'],
		'code': '100000'
	},
	'code': '100010',
	'order': 21
}, {
	'name': '熊谷',
	'enName': 'Saitama',
	'parent': {
		'name': '熊谷',
		'enName': 'Saitama',
		'officeName': '熊谷地方気象台',
		'parent': '010300',
		'children': ['110010', '110020', '110030'],
		'code': '110000'
	},
	'code': '110000',
	'order': 22
}, {
	'name': '銚子',
	'enName': 'Chiba',
	'parent': {
		'name': '銚子',
		'enName': 'Chiba',
		'officeName': '銚子地方気象台',
		'parent': '010300',
		'children': ['120010', '120020', '120030'],
		'code': '120000'
	},
	'code': '120000',
	'order': 26
}, {
	'name': '東京',
	'enName': 'Tokyo Region',
	'parent': {
		'name': '東京都',
		'enName': 'Tokyo',
		'officeName': '気象庁',
		'parent': '010300',
		'children': ['130010', '130020', '130030', '130040'],
		'code': '130000'
	},
	'code': '130010',
	'order': 23
}, {
	'name': '横浜',
	'enName': 'Kanagawa',
	'parent': {
		'name': '横浜',
		'enName': 'Kanagawa',
		'officeName': '横浜地方気象台',
		'parent': '010300',
		'children': ['140010', '140020'],
		'code': '140000'
	},
	'code': '140000',
	'order': 27
}, {
	'name': '新潟',
	'enName': 'Niigata',
	'parent': {
		'name': '新潟',
		'enName': 'Niigata',
		'officeName': '新潟地方気象台',
		'parent': '010500',
		'children': ['150010', '150020', '150030', '150040'],
		'code': '150000'
	},
	'code': '150000',
	'order': 34
}, {
	'name': '富山',
	'enName': 'Toyama',
	'parent': {
		'name': '富山',
		'enName': 'Toyama',
		'officeName': '富山地方気象台',
		'parent': '010500',
		'children': ['160010', '160020'],
		'code': '160000'
	},
	'code': '160000',
	'order': 35
}, {
	'name': '金沢',
	'enName': 'Ishikawa',
	'parent': {
		'name': '金沢',
		'enName': 'Ishikawa',
		'officeName': '金沢地方気象台',
		'parent': '010500',
		'children': ['170010', '170020'],
		'code': '170000'
	},
	'code': '170000',
	'order': 36
}, {
	'name': '福井',
	'enName': 'Fukui',
	'parent': {
		'name': '福井',
		'enName': 'Fukui',
		'officeName': '福井地方気象台',
		'parent': '010500',
		'children': ['180010', '180020'],
		'code': '180000'
	},
	'code': '180000',
	'order': 37
}, {
	'name': '甲府',
	'enName': 'Yamanashi',
	'parent': {
		'name': '甲府',
		'enName': 'Yamanashi',
		'officeName': '甲府地方気象台',
		'parent': '010300',
		'children': ['190010', '190020'],
		'code': '190000'
	},
	'code': '190000',
	'order': 29
}, {
	'name': '長野',
	'enName': 'Nagano',
	'parent': {
		'name': '長野',
		'enName': 'Nagano',
		'officeName': '長野地方気象台',
		'parent': '010300',
		'children': ['200010', '200020', '200030'],
		'code': '200000'
	},
	'code': '200010',
	'order': 28
}, {
	'name': '岐阜',
	'enName': 'Gifu',
	'parent': {
		'name': '岐阜',
		'enName': 'Gifu',
		'officeName': '岐阜地方気象台',
		'parent': '010400',
		'children': ['210010', '210020'],
		'code': '210000'
	},
	'code': '210010',
	'order': 32
}, {
	'name': '静岡',
	'enName': 'Shizuoka',
	'parent': {
		'name': '静岡',
		'enName': 'Shizuoka',
		'officeName': '静岡地方気象台',
		'parent': '010400',
		'children': ['220010', '220020', '220030', '220040'],
		'code': '220000'
	},
	'code': '220000',
	'order': 30
}, {
	'name': '名古屋',
	'enName': 'Aichi',
	'parent': {
		'name': '名古屋',
		'enName': 'Aichi',
		'officeName': '名古屋地方気象台',
		'parent': '010400',
		'children': ['230010', '230020'],
		'code': '230000'
	},
	'code': '230000',
	'order': 31
}, {
	'name': '津',
	'enName': 'Mie',
	'parent': {
		'name': '津',
		'enName': 'Mie',
		'officeName': '津地方気象台',
		'parent': '010400',
		'children': ['240010', '240020'],
		'code': '240000'
	},
	'code': '240000',
	'order': 33
}, {
	'name': '彦根',
	'enName': 'Shiga',
	'parent': {
		'name': '彦根',
		'enName': 'Shiga',
		'officeName': '彦根地方気象台',
		'parent': '010600',
		'children': ['250010', '250020'],
		'code': '250000'
	},
	'code': '250020',
	'order': 38
}, {
	'name': '京都',
	'enName': 'Kyoto',
	'parent': {
		'name': '京都',
		'enName': 'Kyoto',
		'officeName': '京都地方気象台',
		'parent': '010600',
		'children': ['260010', '260020'],
		'code': '260000'
	},
	'code': '260010',
	'order': 39
}, {
	'name': '大阪',
	'enName': 'Osaka Prefecture',
	'parent': {
		'name': '大阪',
		'enName': 'Osaka',
		'officeName': '大阪管区気象台',
		'parent': '010600',
		'children': ['270000'],
		'code': '270000'
	},
	'code': '270000',
	'order': 40
}, {
	'name': '神戸',
	'enName': 'Hyogo',
	'parent': {
		'name': '神戸',
		'enName': 'Hyogo',
		'officeName': '神戸地方気象台',
		'parent': '010600',
		'children': ['280010', '280020'],
		'code': '280000'
	},
	'code': '280010',
	'order': 41
}, {
	'name': '奈良',
	'enName': 'Nara',
	'parent': {
		'name': '奈良',
		'enName': 'Nara',
		'officeName': '奈良地方気象台',
		'parent': '010600',
		'children': ['290010', '290020'],
		'code': '290000'
	},
	'code': '290000',
	'order': 42
}, {
	'name': '和歌山',
	'enName': 'Wakayama',
	'parent': {
		'name': '和歌山',
		'enName': 'Wakayama',
		'officeName': '和歌山地方気象台',
		'parent': '010600',
		'children': ['300010', '300020'],
		'code': '300000'
	},
	'code': '300000',
	'order': 43
}, {
	'name': '鳥取',
	'enName': 'Tottori',
	'parent': {
		'name': '鳥取',
		'enName': 'Tottori',
		'officeName': '鳥取地方気象台',
		'parent': '010700',
		'children': ['310010', '310020'],
		'code': '310000'
	},
	'code': '310000',
	'order': 47
}, {
	'name': '松江',
	'enName': 'Shimane',
	'parent': {
		'name': '松江',
		'enName': 'Shimane',
		'officeName': '松江地方気象台',
		'parent': '010700',
		'children': ['320010', '320020', '320030'],
		'code': '320000'
	},
	'code': '320000',
	'order': 46
}, {
	'name': '岡山',
	'enName': 'Okayama',
	'parent': {
		'name': '岡山',
		'enName': 'Okayama',
		'officeName': '岡山地方気象台',
		'parent': '010700',
		'children': ['330010', '330020'],
		'code': '330000'
	},
	'code': '330010',
	'order': 44
}, {
	'name': '広島',
	'enName': 'Hiroshima',
	'parent': {
		'name': '広島',
		'enName': 'Hiroshima',
		'officeName': '広島地方気象台',
		'parent': '010700',
		'children': ['340010', '340020'],
		'code': '340000'
	},
	'code': '340010',
	'order': 45
}, {
	'name': '下関',
	'enName': 'Yamaguchi',
	'parent': {
		'name': '下関',
		'enName': 'Yamaguchi',
		'officeName': '下関地方気象台',
		'parent': '010900',
		'children': ['350010', '350020', '350030', '350040'],
		'code': '350000'
	},
	'code': '350000',
	'order': 52
}, {
	'name': '徳島',
	'enName': 'Tokushima',
	'parent': {
		'name': '徳島',
		'enName': 'Tokushima',
		'officeName': '徳島地方気象台',
		'parent': '010800',
		'children': ['360010', '360020'],
		'code': '360000'
	},
	'code': '360000',
	'order': 48
}, {
	'name': '高松',
	'enName': 'Kagawa Prefecture',
	'parent': {
		'name': '高松',
		'enName': 'Kagawa',
		'officeName': '高松地方気象台',
		'parent': '010800',
		'children': ['370000'],
		'code': '370000'
	},
	'code': '370000',
	'order': 49
}, {
	'name': '松山',
	'enName': 'Ehime',
	'parent': {
		'name': '松山',
		'enName': 'Ehime',
		'officeName': '松山地方気象台',
		'parent': '010800',
		'children': ['380010', '380020', '380030'],
		'code': '380000'
	},
	'code': '380000',
	'order': 50
}, {
	'name': '高知',
	'enName': 'Kochi',
	'parent': {
		'name': '高知',
		'enName': 'Kochi',
		'officeName': '高知地方気象台',
		'parent': '010800',
		'children': ['390010', '390020', '390030'],
		'code': '390000'
	},
	'code': '390000',
	'order': 51
}, {
	'name': '福岡',
	'enName': 'Fukuoka',
	'parent': {
		'name': '福岡',
		'enName': 'Fukuoka',
		'officeName': '福岡管区気象台',
		'parent': '010900',
		'children': ['400010', '400020', '400030', '400040'],
		'code': '400000'
	},
	'code': '400000',
	'order': 53
}, {
	'name': '佐賀',
	'enName': 'Saga',
	'parent': {
		'name': '佐賀',
		'enName': 'Saga',
		'officeName': '佐賀地方気象台',
		'parent': '010900',
		'children': ['410010', '410020'],
		'code': '410000'
	},
	'code': '410000',
	'order': 55
}, {
	'name': '長崎',
	'enName': 'Nagasaki',
	'parent': {
		'name': '長崎',
		'enName': 'Nagasaki',
		'officeName': '長崎地方気象台',
		'parent': '010900',
		'children': ['420010', '420020', '420030', '420040'],
		'code': '420000'
	},
	'code': '420100',
	'order': 56
}, {
	'name': '熊本',
	'enName': 'Kumamoto',
	'parent': {
		'name': '熊本',
		'enName': 'Kumamoto',
		'officeName': '熊本地方気象台',
		'parent': '010900',
		'children': ['430010', '430020', '430030', '430040'],
		'code': '430000'
	},
	'code': '430000',
	'order': 57
}, {
	'name': '大分',
	'enName': 'Oita',
	'parent': {
		'name': '大分',
		'enName': 'Oita',
		'officeName': '大分地方気象台',
		'parent': '010900',
		'children': ['440010', '440020', '440030', '440040'],
		'code': '440000'
	},
	'code': '440000',
	'order': 54
}, {
	'name': '宮崎',
	'enName': 'Miyazaki',
	'parent': {
		'name': '宮崎',
		'enName': 'Miyazaki',
		'officeName': '宮崎地方気象台',
		'parent': '011000',
		'children': ['450010', '450020', '450030', '450040'],
		'code': '450000'
	},
	'code': '450000',
	'order': 58
}, {
	'name': '名瀬',
	'enName': 'Amami Region',
	'parent': {
		'name': '鹿児島県（奄美地方除く）',
		'enName': 'Kagoshima (Excluding Amami)',
		'officeName': '鹿児島地方気象台',
		'parent': '011000',
		'children': ['460010', '460020', '460030'],
		'code': '460100'
	},
	'code': '460040',
	'order': 60
}, {
	'name': '那覇',
	'enName': 'Okinawa Main Island',
	'parent': {
		'name': '那覇',
		'enName': 'Okinawa Main Island',
		'officeName': '沖縄気象台',
		'parent': '011100',
		'children': ['471010', '471020', '471030'],
		'code': '471000'
	},
	'code': '471000',
	'order': 61
}, {
	'name': '南大東',
	'enName': 'Daitojima Region',
	'parent': {
		'name': '南大東',
		'enName': 'Daitojima',
		'officeName': '南大東島地方気象台',
		'parent': '011100',
		'children': ['472000'],
		'code': '472000'
	},
	'code': '472000',
	'order': 62
}, {
	'name': '宮古島',
	'enName': 'Miyakojima Region',
	'parent': {
		'name': '宮古島',
		'enName': 'Miyakojima',
		'officeName': '宮古島地方気象台',
		'parent': '011100',
		'children': ['473000'],
		'code': '473000'
	},
	'code': '473000',
	'order': 63
}, {
	'name': '石垣島',
	'enName': 'Yaeyama',
	'parent': {
		'name': '石垣島',
		'enName': 'Yaeyama',
		'officeName': '石垣島地方気象台',
		'parent': '011100',
		'children': ['474010', '474020'],
		'code': '474000'
	},
	'code': '474000',
	'order': 64
}, {
	'name': '稚内',
	'enName': 'Soya Region',
	'parent': {
		'name': '稚内',
		'enName': 'Soya',
		'officeName': '稚内地方気象台',
		'parent': '010100',
		'children': ['011000'],
		'code': '011000'
	},
	'code': '011000',
	'order': 1
}, {
	'name': '旭川',
	'enName': 'Kamikawa Rumoi',
	'parent': {
		'name': '旭川',
		'enName': 'Kamikawa Rumoi',
		'officeName': '旭川地方気象台',
		'parent': '010100',
		'children': ['012010', '012020'],
		'code': '012000'
	},
	'code': '012000',
	'order': 2
}, {
	'name': '網走',
	'enName': 'Abashiri Kitami Mombetsu',
	'parent': {
		'name': '網走',
		'enName': 'Abashiri Kitami Mombetsu',
		'officeName': '網走地方気象台',
		'parent': '010100',
		'children': ['013010', '013020', '013030'],
		'code': '013000'
	},
	'code': '013000',
	'order': 3
}, {
	'name': '釧路',
	'enName': 'Kushiro Nemuro',
	'parent': {
		'name': '釧路',
		'enName': 'Kushiro Nemuro',
		'officeName': '釧路地方気象台',
		'parent': '010100',
		'children': ['014010', '014020'],
		'code': '014100'
	},
	'code': '014100',
	'order': 4
}, {
	'name': '帯広',
	'enName': 'Tokachi Region',
	'parent': {
		'name': '釧路',
		'enName': 'Kushiro Nemuro',
		'officeName': '釧路地方気象台',
		'parent': '010100',
		'children': ['014010', '014020'],
		'code': '014100'
	},
	'code': '014030',
	'order': 5
}, {
	'name': '室蘭',
	'enName': 'Iburi Hidaka',
	'parent': {
		'name': '室蘭',
		'enName': 'Iburi Hidaka',
		'officeName': '室蘭地方気象台',
		'parent': '010100',
		'children': ['015010', '015020'],
		'code': '015000'
	},
	'code': '015000',
	'order': 6
}, {
	'name': '札幌',
	'enName': 'Ishikari Sorachi Shiribeshi',
	'parent': {
		'name': '札幌',
		'enName': 'Ishikari Sorachi Shiribeshi',
		'officeName': '札幌管区気象台',
		'parent': '010100',
		'children': ['016010', '016020', '016030'],
		'code': '016000'
	},
	'code': '016000',
	'order': 7
}, {
	'name': '函館',
	'enName': 'Oshima Hiyama',
	'parent': {
		'name': '函館',
		'enName': 'Oshima Hiyama',
		'officeName': '函館地方気象台',
		'parent': '010100',
		'children': ['017010', '017020'],
		'code': '017000'
	},
	'code': '017000',
	'order': 8
}, {
	'name': '青森',
	'enName': 'Tsugaru',
	'parent': {
		'name': '青森県',
		'enName': 'Aomori',
		'officeName': '青森地方気象台',
		'parent': '010200',
		'children': ['020010', '020020', '020030'],
		'code': '020000'
	},
	'code': '020100',
	'order': 9
}, {
	'name': '八戸',
	'enName': 'Shimokita / Sanhachi Kamikita',
	'parent': {
		'name': '青森県',
		'enName': 'Aomori',
		'officeName': '青森地方気象台',
		'parent': '010200',
		'children': ['020010', '020020', '020030'],
		'code': '020000'
	},
	'code': '020030',
	'order': 10
}, {
	'name': '盛岡',
	'enName': 'Inland',
	'parent': {
		'name': '岩手県',
		'enName': 'Iwate',
		'officeName': '盛岡地方気象台',
		'parent': '010200',
		'children': ['030010', '030020', '030030'],
		'code': '030000'
	},
	'code': '030010',
	'order': 12
}, {
	'name': '宮古',
	'enName': 'Engan',
	'parent': {
		'name': '岩手県',
		'enName': 'Iwate',
		'officeName': '盛岡地方気象台',
		'parent': '010200',
		'children': ['030010', '030020', '030030'],
		'code': '030000'
	},
	'code': '030100',
	'order': 13
}, {
	'name': '仙台',
	'enName': 'Eastern Region',
	'parent': {
		'name': '宮城県',
		'enName': 'Miyagi',
		'officeName': '仙台管区気象台',
		'parent': '010200',
		'children': ['040010', '040020'],
		'code': '040000'
	},
	'code': '040010',
	'order': 15
}, {
	'name': '秋田',
	'enName': 'Akita',
	'parent': {
		'name': '秋田',
		'enName': 'Akita',
		'officeName': '秋田地方気象台',
		'parent': '010200',
		'children': ['050010', '050020'],
		'code': '050000'
	},
	'code': '050000',
	'order': 11
}, {
	'name': '山形',
	'enName': 'Yamagata',
	'parent': {
		'name': '山形',
		'enName': 'Yamagata',
		'officeName': '山形地方気象台',
		'parent': '010200',
		'children': ['060010', '060020', '060030', '060040'],
		'code': '060000'
	},
	'code': '060000',
	'order': 14
}, {
	'name': '福島',
	'enName': 'Nakadori / Hamadori',
	'parent': {
		'name': '福島県',
		'enName': 'Fukushima',
		'officeName': '福島地方気象台',
		'parent': '010200',
		'children': ['070010', '070020', '070030'],
		'code': '070000'
	},
	'code': '070100',
	'order': 17
}, {
	'name': '若松',
	'enName': 'Aizu',
	'parent': {
		'name': '福島県',
		'enName': 'Fukushima',
		'officeName': '福島地方気象台',
		'parent': '010200',
		'children': ['070010', '070020', '070030'],
		'code': '070000'
	},
	'code': '070030',
	'order': 18
}, {
	'name': '水戸',
	'enName': 'Ibaraki',
	'parent': {
		'name': '水戸',
		'enName': 'Ibaraki',
		'officeName': '水戸地方気象台',
		'parent': '010300',
		'children': ['080010', '080020'],
		'code': '080000'
	},
	'code': '080000',
	'order': 19
}, {
	'name': '宇都宮',
	'enName': 'Tochigi',
	'parent': {
		'name': '宇都宮',
		'enName': 'Tochigi',
		'officeName': '宇都宮地方気象台',
		'parent': '010300',
		'children': ['090010', '090020'],
		'code': '090000'
	},
	'code': '090000',
	'order': 20
}]


export const CALENDAR_ITEM = [
	// "year", Config inside component
	"month",
	"day",
	"dow",
	"non_business_day",
	"monday",
	"tuesday",
	"wednesday",
	"thursday",
	"friday",
	"saturday",
	"sunday",
	"holiday",
	"consecutive_holiday",
	"day_before_non_business",
	"day_after_non_business",
	"day_before_consecutive",
	"day_after_consecutive",
	"week_no",
]

export const SPECIAL_HOLIDAY_ITEM = [
	"obon",
	"year_end_day",
	["jan2", "Only Count Business Days"],
	["jan3", "Only Count Business Days"],
]

export const BUSINESS_RHYTHM_ITEM = [
	["day_1_month", "Only Count Business Days"],
	["day_2_month", "Only Count Business Days"],
	["day_3_month", "Only Count Business Days"],
	["last_day_month", "Only Count Business Days"],
	["1_to_last_day_month", "Only Count Business Days"],
	["2_to_last_day_month", "Only Count Business Days"],
	["is_first_week", "Only Count Business Days"],
	["is_last_week", "Only Count Business Days"],
	["5th_day", "Only Count Business Days"],
	["10th_day", "Only Count Business Days"],
	["15th_day", "Only Count Business Days"],
	["20th_day", "Only Count Business Days"],
	["25th_day", "Only Count Business Days"],
	"first_business_day_week",
	"last_business_day_week",
	"post_non_business_day",
	"prior_non_business_day",
]

export const TOKEN_TYPE = {
    "USER_TOKEN": "USER_TOKEN",
    "TEAM_TOKEN": "TEAM_TOKEN"
}
